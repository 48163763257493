<template>
  <dv-full-screen-container class="bj" style="width: 100%; height: 100%">
    <dv-border-box-8 style="background: rgba(22, 40, 80, 0.8)">
      <van-popup v-model:show="show" round>
        <dv-border-box-7 :color="['#3D5AA1']" class="popup">
          <div>
            <div class="onheard">
              <img src="../../public/singran.png" alt="" class="heard" />
              <div style="color: #40c5ff">芯任净物打包</div>
            </div>
            <div style="padding-top: 20px; font-size: 16px; color: #40c5ff">
              下单时间：{{ time || dates }}
            </div>
            <van-cell-group v-for="e in bucao_summary" :key="e">
              <van-cell>
                <template #title>
                  <div
                    style="text-align: left; padding-left: 20px; color: #40c5ff"
                  >
                    {{ e.name }}
                  </div>
                </template>
                <template #default>
                  <div style="padding-right: 20px; color: #40c5ff">
                    {{ e.count }}
                  </div>
                </template>
              </van-cell>
            </van-cell-group>
            <van-cell>
              <template #title>
                <div
                  style="text-align: left; padding-left: 20px; color: #40c5ff"
                >
                  总数
                </div>
              </template>
              <template #default>
                <div style="padding-right: 20px; color: #40c5ff">
                  {{ read_count }}
                </div>
              </template>
            </van-cell>
            <div class="dialog_button">
              <dv-border-box-12
                class="dialog_button_but"
                @click="
                  () => {
                    show = !show;
                  }
                "
                >取消</dv-border-box-12
              >
              <dv-border-box-12 class="dialog_button_but" @click="submit"
                >确定</dv-border-box-12
              >
            </div>
          </div>
        </dv-border-box-7>
      </van-popup>
      <van-row type="flex" v-if="!login" style="height: 100%; padding: 10px">
        <van-col span="24" style="height: 5%; display: flex; margin: 5px">
          <div style="width: 25%">
            <div class="info">
              <img
                :src="device?.bandb?.avatar"
                class="logo"
                v-if="device?.bandb?.avatar"
              />
              <img
                src="../assets/slogan.png"
                class="logos"
                v-if="!device?.bandb?.avatar"
              />
              <div>卫生联防联控云平台</div>
            </div>
            <div style="display: flex; margin-top: 20px; align-items: flex-end">
              <img :src="staff_info.avatar" alt="" class="logo" />
              <div style="font-size: 20px; color: #fff; line-height: 35px">
                {{ staff_info?.name }}
              </div>
              <div style="font-size: 20px; color: #fff; line-height: 35px">
                【{{ staff_info?.role }}】
              </div>
            </div>
          </div>
          <div style="width: 50%">
            <div class="onheard">
              <img src="../../public/singran.png" alt="" class="heard" />
              <div class="heardtext">芯任净物打包</div>
            </div>
          </div>
          <div class="device">
            <div>版本号：{{ num }}</div>
            <div v-if="device?.device?.model">
              设备型号：{{ device?.device?.model }}
            </div>
            <div v-if="device?.device?.id">
              设备编号：{{ device?.device?.id }}
            </div>
            <div class="duka">
              读卡设备：<span :style="duka.color">{{ duka.text }}</span>
            </div>
          </div>
        </van-col>
        <van-col
          span="24"
          style="
            height: 30%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
          "
        >
          <div>
            <dv-decoration-9
              style="width: 300px; height: 300px; margin: 0 100px; color: #fff"
              >{{ status_msg }}</dv-decoration-9
            >
          </div>
        </van-col>
        <van-col span="24" style="height: 40%">
          <div class="preview">
            <div style="font-size: 24px; margin-bottom: 20px">
              <!-- <van-icon name="success" color="#1ED76D" v-if="status" /><van-icon
                name="cross"
                color="red"
                v-if="!status"
              /> -->
            </div>
            <div class="status">
              <div
                class="status_msg"
                v-for="(item, index) in status_msgs"
                :key="index"
              >
                <van-icon
                  name="success"
                  color="#1ED76D"
                  v-if="item.status"
                /><van-icon
                  name="cross"
                  color="red"
                  v-if="!item.status && !item.wran"
                />
                <van-icon
                  name="warn-o"
                  color="yellow"
                  v-if="item.wran && !item.status"
                />{{ item.msg }}{{ item.status ? "成功" : "失败" }}
              </div>
            </div>
          </div>
          <van-button
            class="buttonS"
            type="success"
            :loading="loading"
            loading-type="spinner"
            @click="resetdevice"
            >重置读卡
          </van-button>
          <van-button
            class="button"
            type="success"
            @click="restarts"
            v-if="!status"
            >重启</van-button
          >
        </van-col>
      </van-row>
      <van-row v-if="login" type="flex" style="height: 100%; padding: 10px">
        <van-col span="24" style="height: 5%; display: flex; margin: 5px">
          <div style="width: 25%">
            <div class="info">
              <!-- <img
                :src="data.factory?.avatar"
                class="logo"
                v-if="data.factory?.avatar"
              /> -->
              <img src="../assets/slogan.png" class="logos" />
              <div>卫生联防联控云平台</div>
            </div>
            <div
              style="display: flex; margin-top: 20px; align-items: flex-end"
              v-if="staff_info"
            >
              <img :src="staff_info.avatar" alt="" class="logo" />
              <div style="font-size: 20px; color: #fff; line-height: 35px">
                {{ staff_info?.name }}
              </div>
              <div style="font-size: 20px; color: #fff; line-height: 35px">
                【{{ staff_info?.role }}】
              </div>
            </div>
            <div class="infotime" v-if="time">{{ time || dates }}</div>
            <div class="infotime" v-if="service">批次号：{{ service }}</div>
          </div>
          <div style="width: 50%">
            <div class="onheard">
              <img src="../../public/singran.png" alt="" class="heard" />
              <div class="heardtitle">芯任净物打包</div>
            </div>
          </div>
          <div class="device">
            <div>版本号：{{ num }}</div>
            <div>设备型号：{{ device.device?.model }}</div>
            <div>设备编号：{{ device.device?.id }}</div>
            <div class="duka">
              读卡设备：<span :style="duka.color">{{ duka.text }}</span>
            </div>
          </div>
        </van-col>
        <van-col span="8" style="height: 85%">
          <dv-decoration-9 class="zero" :dur="dur">
            <countTo
              :startVal="uncount"
              :endVal="msg?.count || 0"
              :duration="3000"
            ></countTo
          ></dv-decoration-9>
          <div class="dialog_button">
            <dv-border-box-12 class="dialog_button_but" @click="stress"
              >重读</dv-border-box-12
            >
            <dv-border-box-12 class="dialog_button_but" @click="zero"
              >归零</dv-border-box-12
            >
          </div>
        </van-col>
        <van-col span="10" style="height: 85%">
          <dv-border-box-13 class="btn">
            <div class="border" :style="{ height: listheight }">
              <div
                class="app-card-content"
                v-if="bucao_summary && bucao_summary.length < 1"
              >
                <div
                  :style="'width: ' + (100 / lineNumber - 2.2) + '%'"
                  :class="['app-card-content_item']"
                >
                  <dv-border-box-7 :color="['#3D5AA1']">
                    <div class="fenlei">
                      <div class="box">0</div>
                      <div class="box_name">被套</div>
                    </div>
                  </dv-border-box-7>
                </div>
                <div
                  :style="'width: ' + (100 / lineNumber - 2.2) + '%'"
                  :class="['app-card-content_item']"
                >
                  <dv-border-box-7 :color="['#3D5AA1']">
                    <div class="fenlei">
                      <div class="box">0</div>
                      <div class="box_name">床单</div>
                    </div>
                  </dv-border-box-7>
                </div>
                <div
                  :style="'width: ' + (100 / lineNumber - 2.2) + '%'"
                  :class="['app-card-content_item']"
                >
                  <dv-border-box-7 :color="['#3D5AA1']">
                    <div class="fenlei">
                      <div class="box">0</div>
                      <div class="box_name">枕套</div>
                    </div>
                  </dv-border-box-7>
                </div>
                <div
                  :style="'width: ' + (100 / lineNumber - 2.2) + '%'"
                  :class="['app-card-content_item']"
                >
                  <dv-border-box-7 :color="['#3D5AA1']">
                    <div class="fenlei">
                      <div class="box">0</div>
                      <div class="box_name">浴巾</div>
                    </div>
                  </dv-border-box-7>
                </div>
                <div
                  :style="'width: ' + (100 / lineNumber - 2.2) + '%'"
                  :class="['app-card-content_item']"
                >
                  <dv-border-box-7 :color="['#3D5AA1']">
                    <div class="fenlei">
                      <div class="box">0</div>
                      <div class="box_name">面巾</div>
                    </div>
                  </dv-border-box-7>
                </div>
                <div
                  :style="'width: ' + (100 / lineNumber - 2.2) + '%'"
                  :class="['app-card-content_item']"
                >
                  <dv-border-box-7 :color="['#3D5AA1']">
                    <div class="fenlei">
                      <div class="box">0</div>
                      <div class="box_name">地巾</div>
                    </div>
                  </dv-border-box-7>
                </div>
              </div>
              <div
                class="app-card-content"
                v-if="bucao_summary && bucao_summary.length > 0"
              >
                <div
                  v-for="(item, index) in bucao_summary"
                  :key="index"
                  :style="'width: ' + (100 / lineNumber - 2.2) + '%'"
                  :class="['app-card-content_item', getBorderClass(index)]"
                >
                  <dv-border-box-7 :color="['#3D5AA1']">
                    <div class="fenlei">
                      <div class="box">
                        <countTo
                          :startVal="item?.uncount || 0"
                          :endVal="item?.count || 0"
                          :duration="3000"
                        ></countTo>
                      </div>
                      <div class="box_name">{{ item.name }}</div>
                      <div class="box_name_size">{{ item.size }}</div>
                    </div>
                  </dv-border-box-7>
                </div>
              </div>
              <div
                v-if="msg.unknown_bucao_count > 0"
                style="color: red; font-size: 32px"
              >
                当前未知芯片数量：{{ msg.unknown_bucao_count }}
              </div>
            </div>
          </dv-border-box-13>
        </van-col>
        <van-col span="6" style="height: 85%">
          <dv-border-box-6
            :color="['#3E6BAF', '#30EAF9']"
            style="padding: 10px 0px 0 10px"
          >
            <div class="order_title">打包明细</div>
            <div class="title_conter">
              <div class="title_text">
                <div>下单时间：</div>
                <div style="font-size: 20px; color: #fff">
                  {{ time || dates }}
                </div>
              </div>
              <div class="title_text">
                <div>下单人员：</div>
                <div style="font-size: 20px; color: #fff">
                  {{ staff_info?.name }}
                </div>
              </div>
              <div class="title_text">
                <div>客户名称：</div>
                <div style="font-size: 20px; color: #fff">
                  {{ hotels[0]?.bandb_name }}
                </div>
              </div>
              <div class="title_text">
                <div>联系人：</div>
                <div style="font-size: 20px; color: #fff">
                  {{ hotels[0]?.contact }}
                </div>
              </div>
              <div class="title_text">
                <div>客户电话：</div>
                <div style="font-size: 20px; color: #fff">
                  {{ hotels[0]?.phone }}
                </div>
              </div>
              <div class="title_text">
                <div style="width: 25%">客户地址：</div>
                <div style="font-size: 20px; color: #fff; width: 75%">
                  {{ hotels[0]?.address }}
                </div>
              </div>
            </div>
            <div class="anniu">
              <dv-border-box-12
                class="annius"
                v-if="msg.status == 0"
                @click="wash"
                >确认打包</dv-border-box-12
              >
            </div>
          </dv-border-box-6>
        </van-col>
      </van-row>
    </dv-border-box-8>
  </dv-full-screen-container>
</template>
<script>
import { ref } from "vue";
import store from "../store/index";
import countTo from "./vue-countTo.vue";
import api from "../utils/api";
import mqtt from "mqtt";
import _ from "lodash";
import {
  Col,
  Row,
  Tab,
  Tabs,
  Icon,
  List,
  Cell,
  CellGroup,
  PullRefresh,
  Button,
  Card,
  Popup,
  Tag,
  Swipe,
  SwipeItem,
  Image as VanImage,
  Notify,
  Overlay,
  Loading,
  CountDown,
  Circle,
  Toast,
  Progress,
  Checkbox,
  CheckboxGroup,
  Field,
  Dialog,
  Collapse,
  CollapseItem,
} from "vant";
export default {
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Icon.name]: Icon,
    [List.name]: List,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [PullRefresh.name]: PullRefresh,
    [Button.name]: Button,
    [Card.name]: Card,
    [Popup.name]: Popup,
    [Tag.name]: Tag,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [VanImage.name]: VanImage,
    [Notify.name]: Notify,
    [Overlay.name]: Overlay,
    [Loading.name]: Loading,
    [CountDown.name]: CountDown,
    [Circle.name]: Circle,
    [Toast.name]: Toast,
    [Progress.name]: Progress,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Field.name]: Field,
    [Dialog.name]: Dialog,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    countTo,
  },
  setup() {
    const num = store.state.edition;
    const show = ref(false);
    const wash = () => {
      show.value = true;
    };
    const activeNames = ref("1");
    const duka = ref({
      text: "网络已连接",
      color: "color:green",
    });
    return {
      duka,
      login: ref(false),
      activeNames,
      num,
      show,
      wash,
      lineNumber: 3,
      device: ref({}),
      device_id: ref(""),
      mtopic: ref(""),
      mtopic_order: ref(""),
      mtopicheart: ref(""),
      staff_info: ref({}),
      status_msg: ref(""),
      status_msgs: ref([
        {
          msg: "设备获取",
          status: false,
          warn: false,
        },
        {
          msg: "芯任桌面智慧工作站设备配置",
          status: false,
          warn: false,
        },
        {
          msg: "芯任桌面读卡器设备配置",
          status: false,
          warn: false,
        },
        {
          msg: "芯任桌面票据打印机设备配置",
          status: false,
          warn: false,
        },
        {
          msg: "云端连接",
          status: false,
          warn: false,
        },
        {
          msg: "service云端订阅",
          status: false,
          warn: false,
        },
      ]),
      service: ref(""),
      status: ref(""),
      read_count: ref(0),
      clientId: ref(""),
      order: ref({}),
      listheight: ref("3.8rem"),
      dur: ref(0),
      uncount: ref(0),
      msg: ref({}),
      bucao_summary: ref([]),
      time: ref(""),
      dates: ref(""),
      hotels: ref([]),
      click: ref(false),
      lctf_device_code: ref(""),
      loading: ref(false),
      networks: ref(false),
      network_time: 5000,
      network_Id: null,
      resetnum: ref(0),
      restartsnum: ref(0),
      restartsts: ref(),
      resetnumts: ref(),
      mycount: ref(0),
      device_type: ref(""),
      bucao_type: ref(),
    };
  },
  mounted() {
    document.title = "芯任净物打包";
    this.network();
    this.network_refreh();
    this.dates = this.date(new Date().getTime());
    this.clientId =
      this.$route.query.device_code + "_" + Math.floor(Math.random() * 10 + 1);
    this.staffgetstaffinfo();
    this.devicefetch(this.$route.query.device_code);
  },
  watch: {
    msg: function (newVal, endVal) {
      if (endVal.count && endVal != newVal) {
        if (endVal.code == newVal.code) {
          this.uncount = endVal.count;
          if (newVal.size_records) {
            newVal = newVal.size_records.map((i) => {
              endVal.size_records.map((item) => {
                if (i.code == item.code) {
                  i.uncount = item.count;
                }
              });
            });
          }
        } else {
          this.uncount == 0;
          newVal = newVal.size_records.map((i) => {
            i.uncount = 0;
          });
        }
      }
    },
  },
  methods: {
    _isMobile(e) {
      this.flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      if (this.flag == null) {
        console.log("==>PC端");
      } else {
        console.log("==>移动端");
        window.Android.playTone(e);
      }
    },
    network() {
      if (window.navigator.onLine == true) {
        let img = new Image();
        img.src =
          "http://fdfs.singran.vip/group1/M00/39/36/rBEAA2DAhiaAcZK4AADaqwfWS6s275.png";
        img.onload = () => {
          console.log("网络已连接");
          // this.status_msg = "网络已连接";
        };
        img.onerror = () => {
          console.log("无网络连接，请检查网络");
          this.status_msg = "无网络连接，请检查网络";
          if (this.status_msgs) {
            this.status_msgs.map((i) => {
              i.status = false;
              i.warn = false;
            });
          }
          Dialog.alert({
            title: "网络已断开连接",
            message: "请检查网络",
          }).then(() => {});
          this._isMobile("cut_off_internet");
        };
      } else {
        console.log("网络未连接--请检查网络");
      }
    },
    network_refreh() {
      if (this.network_Id) {
        clearInterval(this.network_Id);
      } else {
        console.log(`打开定时器${this.network_time}`);
        this.network_Id = setInterval(() => {
          this.network();
        }, this.network_time);
      }
    },
    restart() {
      this.login = false;
    },
    restarts() {
      if (this.restartsts) {
        if (
          new Date().getTime() - this.restartsts < 3000 &&
          this.restartsnum >= 5
        ) {
          Notify({
            message: "请勿频繁操作",
            duration: 3000,
            type: "danger",
          });
          this._isMobile("often_verify");
          this.restartsnum = 0;
        } else {
          this.restartsnum++;
        }
        if (new Date().getTime() - this.restartsts > 3000) {
          this.restartsnum = 0;
        }
      }
      this.restartsts = new Date().getTime();
      this.login = false;
    },
    //切换app的bug
    resetdevice() {
      console.log(
        new Date().getTime(),
        this.resetnumts,
        new Date().getTime() - this.resetnumts,
        this.resetnum
      );
      if (this.resetnumts) {
        if (
          new Date().getTime() - this.resetnumts < 3000 &&
          this.resetnum >= 5
        ) {
          Notify({
            message: "请勿频繁操作",
            duration: 3000,
            type: "danger",
          });
          this._isMobile("often_verify");
          this.resetnum = 0;
        } else {
          this.resetnum++;
        }
        if (new Date().getTime() - this.resetnumts > 3000) {
          this.resetnum = 0;
        }
      }
      this.resetnumts = new Date().getTime();
      this.loading = true;
      api
        .resetdevice({
          device_code: this.lctf_device_code,
          cmd: "1",
        })
        .then((res) => {
          console.log(res);
          if (res.data.errcode == 0) {
            this.loading = res.loading;
            Notify({
              message: "已发送重启命令",
              duration: 1000,
              type: "success",
            });
          }
          // else {
          //   Notify({ message: "重置读卡失败", duration: 1000, type: "danger" });
          // }
        });
      // .catch((err) => {
      //   console.log(err);
      //   Notify({ message: err.errmsg, duration: 1000, type: "danger" });
      // });
    },
    devicefetch(e) {
      api
        .devicefetch({
          device_code: e,
        })
        .then((res) => {
          console.log("设备", res.data);
          if (res.data.errcode != 0) {
            Dialog.alert({
              title: res.data.errmsg,
              message: "请检查平板归属与设备类型",
            }).then(() => {
              // on confirm
            });
            this.status_msg = "设备获取失败";
            this.status_msgs[0].status = false;
            return;
          }
          this.status_msg = "设备获取成功";
          this.status_msgs[0].status = true;
          if (res.data.data.device.category == "dpod") {
            setTimeout(() => {
              if (res.data.data.device.code) {
                this.status_msg = res.data.data.device.name + "设备配置成功";
                this.status_msgs[1].status = true;
                this.status = true;
              } else {
                this.status_msg = this.status_msgs[1].msg + "失败";
                this.status = false;
                this._isMobile("check_belong");
                return;
              }
            }, 200);
            setTimeout(() => {
              if (res.data.data.device.lctf) {
                this.status_msg =
                  res.data.data.device.lctf_name + "设备配置成功";
                this.status_msgs[2].status = true;
                this.status = true;
              } else {
                this.status_msg = this.status_msgs[2].msg + "失败";
                this.status = false;
                this._isMobile("check_belong");
                return;
              }
            }, 400);
            setTimeout(() => {
              if (res.data.data.device.prnt) {
                this.status_msg =
                  res.data.data.device.prnt_name + "设备配置成功";
                this.status_msgs[3].status = true;
                this.status = true;
              } else {
                this.status_msg = this.status_msgs[3].msg + "失败";
                this.status = false;
                return;
              }
            }, 600);
          } else if (res.data.data.device.category == "lctf") {
            setTimeout(() => {
              if (res.data.data.dpod.code) {
                this.status_msg = res.data.data.dpod.name + "设备配置成功";
                this.status_msgs[1].status = true;

                this.status = true;
              } else {
                this.status_msg = this.status_msgs[1].msg + "失败";
                this.status = false;
                this._isMobile("check_belong");
                return;
              }
            }, 200);
            setTimeout(() => {
              if (res.data.data.dpod.lctf) {
                this.status_msg = res.data.data.dpod.lctf_name + "设备配置成功";
                this.status_msgs[2].status = true;
                this.status = true;
              } else {
                this.status_msg = this.status_msgs[2].msg + "失败";
                this.status = false;
                this._isMobile("check_belong");
                return;
              }
            }, 400);
            setTimeout(() => {
              if (res.data.data.dpod.prnt) {
                this.status_msg = res.data.data.dpod.prnt_name + "设备配置成功";
                this.status_msgs[3].status = true;
                this.status = true;
              } else {
                this.status_msg = this.status_msgs[3].msg + "失败";
                this.status = false;
                return;
              }
            }, 600);
          }
          this.device = res.data.data;
          this.device_id = res.data.data.device.id;
          this.device_type =
            res.data.data.equipment.rfid_mode ||
            res.data.data.lctf_equipment.rfid_mode;
          this.readerstatus();
          this.lctf_device_code =
            res.data.data.device.lctf || res.data.data.dpod.lctf;
          this.mtopic =
            "device/" +
            (res.data.data.device.lctf || res.data.data.dpod.lctf) +
            "/service";
          this.mtopicheart =
            "device/" +
            (res.data.data.device.lctf || res.data.data.dpod.lctf) +
            "/heart";
          this.mqtt();
        });
      // .catch((err) => {
      //   Notify({ message: err.errmsg, duration: 1000, type: "danger" });
      // });
    },
    staffgetstaffinfo() {
      api
        .staffgetstaffinfo({
          staff_code: this.$route.query.staff_code,
          staff_type: this.$route.query.staff_type,
        })
        .then((res) => {
          console.log("staff", res.data);
          if (res.data.errcode == 0) {
            res.data.data.staff_info.avatar =
              "http://api.singran.vip/staffavatar/" +
              res.data.data.staff_info.code;
            this.staff_info = res.data.data.staff_info;
            if (res.data.data.staff_info.role_code == "leaser_ywy") {
              res.data.data.staff_info.role = "业务员";
            } else if (res.data.data.staff_info.role_code == "leaser_shy") {
              res.data.data.staff_info.role = "配送员";
            } else if (res.data.data.staff_info.role_code == "leaser_kfzg") {
              res.data.data.staff_info.role = "库房主管";
            } else if (res.data.data.staff_info.role_code == "leaser_zzl") {
              res.data.data.staff_info.role = "总经理";
            } else if (res.data.data.staff_info.role_code == "leaser_ywjl") {
              res.data.data.staff_info.role = "业务经理";
            } else if (res.data.data.staff_info.role_code == "leaser_cw") {
              res.data.data.staff_info.role = "财务";
            }
          }
        });
      // .catch((err) => {
      //   Notify({ message: err.errmsg, duration: 1000, type: "danger" });
      // });
    },
    mqtt() {
      this.client = mqtt.connect("ws://mqtt.singran.vip:8083/mqtt", {
        clientId: this.clientId,
        clean: true,
        // reconnect: true,
        keepAliveInterval: 30,
        // reconnectInterval: 10,
        username: "xinren_factory",
        password: "xinren20200807",
      });
      // 断开发起重连
      // this.client.on("reconnect", (error) => {
      //   console.log("正在重连:", error);
      //   this.status_msg = "云端尝试重新连接中";
      //   this.status_msgs.push({ msg: "云端尝试重新连接中", status: false });
      // });
      // 链接异常处理
      this.client.on("error", (error) => {
        console.log("连接失败:", error);
        this.status_msg = "云端断开连接";
        Dialog.alert({
          title: "云端断开连接",
          message: "平板未连接上云端，请联系芯任人员",
        }).then(() => {
          // on close
        });
      });
      this.client.on("connect", (e) => {
        console.log("连接成功", e);
        setTimeout(() => {
          this.status_msg = "云端连接成功";
          this.status_msgs[4].status = true;
        }, 1000);
        this.client.subscribe(this.mtopic, (err) => {
          if (!err) {
            console.log("订阅成功:" + this.mtopic);
            setTimeout(() => {
              this.status_msg = "service云端订阅成功";
              this.status_msgs[5].status = true;
            }, 1200);
          } else {
            setTimeout(() => {
              this.status_msg = "service云端订阅失败";
              this._isMobile("connect_cloud");
            }, 1200);
          }
          setTimeout(() => {
            this.status_msg = "等待读卡中...";
          }, 1300);
        });
        this.client.subscribe(this.mtopicheart, (err) => {
          if (!err) {
            console.log("订阅成功:" + this.mtopicheart);
          }
        });
        this.client.on("message", (topic, message) => {
          console.log("recv msg ", topic, " => ", String(message));
          this.msg = JSON.parse(String(message));
 console.log("测试",topic);
          if (topic.indexOf("service") != -1) {
            console.log("读卡推送", this.msg);
            if (this.msg.status == 0) {
              console.log("进行中 ===>");
              this.login = true;
              this.dur = 3;
              this.read_count = this.msg.count;
              this.service = this.msg.service;
              this.time = this.date(this.msg.ts);
              this.bucao_summary = this.msg.size_records;
              // this.gethotelfromservice();
              this.mycount++;
              if (this.mycount == 1) {
                this.rfidbucaotype();
              }
            } else {
              console.log("已关闭 ===>");
              // this.login = false;
              this.dur = 0;
              this.read_count = 0;
              this.uncount = 0;
              this.msg = {};
              this.service = "";
              this.time = "";
              this.bucao_summary = [];
              this.restart();
              this.click = false;
              this.mycount = 0;
            }
          }
          if (topic.indexOf("heart") != -1) {
            console.log("心跳", this.msg);
            if (this.msg.connect == 0) {
              this.duka = {
                text: "已断开连接",
                color: "color:red",
              };
              Dialog.alert({
                title: "读卡设备已断开网络，请检查网络",
                message: "请检查网络",
              }).then(() => {});
            } else {
              this.duka = {
                text: "网络已连接",
                color: "color:green",
              };
            }
          }
        });
      });
    },
    rfidbucaotype() {
      console.log(this.device_type);
      api
        .rfidbucaotype({
          service: this.service,
          device_type: this.device_type,
        })
        .then((res) => {
          console.log("布草", res.data);
          this.bucao_type = res.data.data.bucao_type;
          if (
            res.data.data.bucao_type == "lease" &&
            this.$route.query.staff_type == "leaser"
          ) {
            console.log("租赁布草");
          }
          if (
            res.data.data.bucao_type == "own" &&
            this.$route.query.staff_type == "factory"
          ) {
            console.log("自有布草");
          }
          if (res.data.data.bucao_type == "mix") {
            console.log("混合布草");
            Notify({
              type: "danger",
              duration: 3000,
              message: "不能同时不同类型布草,请分拣后再读卡",
            });
          }
          if (
            res.data.data.bucao_type == "lease" &&
            this.$route.query.staff_type == "factory"
          ) {
            Notify({
              type: "danger",
              duration: 3000,
              message: "当前布草为租赁布草，请使用租赁身份登录",
            });
          }
          if (
            res.data.data.bucao_type == "own" &&
            this.$route.query.staff_type == "leaser"
          ) {
            Notify({
              type: "danger",
              duration: 3000,
              message: "当前布草为自有布草，请使用工厂身份登录",
            });
          }
        });
    },
    readerstatus() {
      api
        .readerstatus({
          device_code: this.device?.dpod?.lctf || this.device?.device?.lctf,
        })
        .then((res) => {
          console.log("首次心跳", res);
          if (res.data.data.status == 1) {
            this.duka = {
              text: "网络已连接",
              color: "color:green",
            };
          } else {
            this.duka = {
              text: "已断开连接",
              color: "color:red",
            };
            Dialog.alert({
              title: "读卡设备已断开网络，请检查网络",
              message: "请检查网络",
            }).then(() => {});
          }
        });
    },
    factorydesk() {
      api.factorydesk({ service: this.service }).then((res) => {
        console.log("批次", res);
        if (res.data.data.bucao_summary) {
          this.dur = 3;
          this.msg.count = res.data.data.bucao_count;
          this.msg.status = 0;
          this.read_count = res.data.data.bucao_count;
          this.bucao_summary = res.data.data.bucao_summary;
          this.login = true;
        }
      });
      // .catch((err) => {
      //   Notify({ message: err.errmsg, duration: 1000, type: "danger" });
      // });
    },
    gethotelfromservice() {
      api.gethotelfromservice({ service: this.service }).then((res) => {
        console.log("关联酒店", res.data);
        if (res.data.errcode == 0) {
          this.hotels = res.data.data.hotels;
        }
      });
      // .catch((err) => {
      //   Notify({ message: err.errmsg, duration: 1000, type: "danger" });
      // });
    },
    throttlefactorydesk: _.throttle(function () {
      this.factorydesk();
    }, 500),
    submit() {
      if (!this.click) {
        this.click = true;
        console.log("确认下单");
        console.log(this.bucao_summary);
        let number = 0;
        this.bucao_summary.map((e) => {
          if (e.count > 0) {
            number++;
          }
        });
        console.log(number);
        let data;
        if (this.device.device.category == "dpod") {
          if (this.staff_info.leaser_code) {
            data = {
              service: this.service,
              leaser_code: this.staff_info.leaser_code,
              device_code: this.device.device.code,
              device_type: this.device_type,
              staff_code: this.$route.query.staff_code,
              staff_type: this.$route.query.staff_type,
              app_scene: this.$route.query.app_scene,
              app_code: this.$route.query.app_id,
              type: this.bucao_type,
            };
          } else {
            data = {
              service: this.service,
              factory_code: this.staff_info.factory_code,
              device_code: this.device.device.code,
              device_type: this.device_type,
              staff_code: this.$route.query.staff_code,
              staff_type: this.$route.query.staff_type,
              app_scene: this.$route.query.app_scene,
              app_code: this.$route.query.app_id,
              type: this.bucao_type,
            };
          }
        } else if (this.device.device.category == "lctf") {
          if (this.staff_info.leaser_code) {
            data = {
              service: this.service,
              leaser_code: this.staff_info.leaser_code,
              device_code: this.device.dpod.code,
              device_type: this.device_type,
              staff_code: this.$route.query.staff_code,
              staff_type: this.$route.query.staff_type,
              app_scene: this.$route.query.app_scene,
              app_code: this.$route.query.app_id,
              type: this.bucao_type,
            };
          } else {
            data = {
              service: this.service,
              factory_code: this.staff_info.factory_code,
              device_code: this.device.dpod.code,
              device_type: this.device_type,
              staff_code: this.$route.query.staff_code,
              staff_type: this.$route.query.staff_type,
              app_scene: this.$route.query.app_scene,
              app_code: this.$route.query.app_id,
              type: this.bucao_type,
            };
          }
        }
        api.packorderpack(data).then((res) => {
          console.log("下单", res);

          if (res.data.errcode != 0) {
            Dialog.alert({
              title: res.data.errmsg,
            }).then(() => {
              // on close
            });
            return;
          }
          this.show = !this.show;
          Toast.success({
            message: "操作成功！",
          });
          setTimeout(() => {
            this.restart();
            this.click = false;
          }, 500);
        });
        // .catch((err) => {
        //   Notify({ message: err.errmsg, duration: 1000, type: "danger" });
        // });
      }
    },
    stress() {
      this.msg = {};
      this.uncount = 0;
      this.deskservice("1");
      setTimeout(() => {
        this.restart();
      }, 500);
    },
    zero() {
      this.msg = {};
      this.uncount = 0;
      this.bucao_summary = [];
      this.deskservice("1");
    },
    deskservice(e) {
      console.log(this.service);
      // this.clears();
      api
        .servicecontrol({ code: this.service, status: e, device_type: "box" })
        .then((res) => {
          console.log("操作", e, this.service, res);
          if (res.data.errcode == 0 && e == 1) {
            Notify({ message: "已关闭", duration: 1000, type: "danger" });
          } else if (res.data.errcode == 0 && e == 2) {
            Notify({ message: "已暂停", duration: 1000, type: "warning" });
          } else if (res.data.errcode == 0 && e == 3) {
            Notify({ message: "已继续", duration: 1000, type: "success" });
          }
        });
    },
    date(e) {
      var date = new Date(e);
      var YY = date.getFullYear() + "-";
      var MM =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var hh =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var mm =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var ss =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return YY + MM + DD + " " + hh + mm + ss;
    },
    getBorderClass(index) {
      index += 1;
      let className = "";
      let lineNumber = this.lineNumber; // 每行行显示个数
      let total = this.bucao_summary.length; // 总个数
      let line = Math.ceil(total / lineNumber); // 一共有几行
      let currentLine = 0; // 当前在第几行,这里从1开始
      let currentIndex = 0; // 当前在某行的第几个
      currentLine = Math.ceil(index / lineNumber);
      currentIndex = index % lineNumber === 0 ? lineNumber : index % lineNumber;
      // 右下边框
      if (currentLine < line && currentIndex < lineNumber) {
        className = "border-bottom border-right";
      }
      // 右边框
      if (currentLine === line && currentIndex < lineNumber) {
        className = "border-right";
      }
      // 下边框
      if (currentLine < line && currentIndex === lineNumber) {
        className = "border-bottom";
      }
      // 无边框
      if (currentLine === line && currentIndex === lineNumber) {
        className = "";
      }
      return className;
    },
  },
};
</script>
<style lang='less' scoped>
/deep/.van-popup {
  background: rgba(0, 0, 0, 0);
}
/deep/.van-cell-group {
  background: rgba(0, 0, 0, 0);
}
/deep/.van-cell {
  background: rgba(0, 0, 0, 0);
  padding: 3px;
  justify-content: space-between;
  align-items: center;
}
/deep/.van-collapse-item__content {
  display: flex;
  justify-content: center;
  background: rgba(0, 0, 0, 0);
}
.bj {
  background-image: url("../assets/bj.jpg");
}
.logo {
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 2px;
  margin-right: 3px;
}
.logos {
  width: 20px;
  height: 10px;
  background: #fff;
  border-radius: 5px;
}
.info {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 12px;
  height: 10px;
  margin-bottom: 2px;
}
.infotime {
  font-size: 12px;
  color: #fff;
  text-align: left;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.onheard {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  font-size: 16px;
  color: #fff;
}
.heard {
  height: 16px;
  width: 16px;
}
.device {
  color: #fff;
  font-size: 10px;
  width: 25%;
  text-align: right;
  margin-right: 10px;
}
.preview {
  font-size: 10px;
  color: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}
.status_msg {
  border-top: 1px solid rgb(255, 255, 255);
  padding: 2px;
  text-align: left;
}
.status {
  width: 20%;
  margin: 10px auto;
  height: 150px;
  overflow-y: scroll;
}
.status::-webkit-scrollbar {
  display: none;
}
.onheard {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  font-size: 16px;
  color: #fff;
}
.heard {
  height: 16px;
  width: 16px;
}
.device {
  color: #fff;
  font-size: 10px;
  width: 25%;
  text-align: right;
  margin-right: 10px;
}
.border {
  border-radius: 5px;
  padding: 3px;
}
.app-card-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: rgba(12, 24, 51, 0);
}
.app-card-content_item {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  margin: 4px;
}
.fenlei {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.box {
  font-size: 30px;
  color: #40c5ff;
}
.box_name {
  font-size: 18px;
  color: #4a9cbd;
}
.box_name_size {
  font-size: 14px;
  color: #2e718b;
}
.btn {
  padding-top: 5px;
}
.qrcode {
  width: 150px;
  height: 150px;
  border: 1px solid black;
}
.heardtext {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  font-size: 16px;
  color: #fff;
}
.zero {
  width: 200px;
  height: 200px;
  margin: 50px auto;
  font-size: 50px;
  color: #ffd900;
}
.dialog_button {
  display: flex;
  justify-content: space-around;
}
.dialog_button_but {
  height: 50px;
  margin-top: 5px;
  width: 45%;
  font-size: 20px;
  line-height: 50px;
  color: #40c5ff;
}
.dialog_button_buts {
  height: 40px;
  margin-top: 45px;
  width: 45%;
  font-size: 16px;
  line-height: 40px;
  color: #40c5ff;
}
.anniu {
  color: #7ce7fd;
  font-size: 12px;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%);
}
.annius {
  width: 100px;
  height: 40px;
  margin-left: 5px;
  line-height: 40px;
  text-align: center;
  color: #4cc9d9;
  font-size: 16px;
}
.popup {
  width: 300px;
  padding: 10px 20px;
  background: rgba(0, 0, 0, 0);
}
.order_title {
  font-size: 16px;
  color: #3fc0fa;
}
.title_conter {
  padding: 3px 5px;
  width: 100%;
  font-size: 10px;
}
.title_order {
  font-size: 9px;
  color: #3fc0fa;
}
.title_text {
  margin: 8px 0;
  width: 92%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: #78ccf3;
}
.button {
  width: 80px;
  height: 40px;
  position: fixed;
  right: 120px;
  bottom: 10px;
}
.buttonS {
  width: 100px;
  height: 40px;
  position: fixed;
  right: 10px;
  bottom: 10px;
}
.duka {
  font-size: 8px;
}
</style>
