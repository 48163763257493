<template>
  <Boxpack />
</template>

<script>
import Boxpack from "../../../components/factory_boxpack";

export default {
  name: "factory_boxpack",
  components: {
    Boxpack,
  },
};
</script>